var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "MapPinIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.domesticTicket')))])];
      },
      proxy: true
    }])
  }, [_c('FeeTab', {
    attrs: {
      "ticket-type": "INLAND",
      "fee-configs-data": _vm.feeConfigsData.inland,
      "agency-id": _vm.agencyId
    },
    on: {
      "refetch-data": _vm.getFeeConfigsData
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-0 mr-sm-50",
          attrs: {
            "icon": "NavigationIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "d-none d-sm-inline"
        }, [_vm._v(_vm._s(_vm.$t('fee.internationalTicket')))])];
      },
      proxy: true
    }])
  }, [_c('FeeTab', {
    attrs: {
      "ticket-type": "INTERNATIONAL",
      "fee-configs-data": _vm.feeConfigsData.international,
      "agency-id": _vm.agencyId
    },
    on: {
      "refetch-data": _vm.getFeeConfigsData
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }