<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon
          icon="MapPinIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $t('fee.domesticTicket') }}</span>
      </template>
      <FeeTab
        ticket-type="INLAND"
        :fee-configs-data="feeConfigsData.inland"
        :agency-id="agencyId"
        @refetch-data="getFeeConfigsData"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="NavigationIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $t('fee.internationalTicket') }}</span>
      </template>
      <FeeTab
        ticket-type="INTERNATIONAL"
        :fee-configs-data="feeConfigsData.international"
        :agency-id="agencyId"
        @refetch-data="getFeeConfigsData"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import {
  onMounted, ref, computed,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { apiAgencies } from '@/api'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'

import FeeTab from './FeeTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    FeeTab,
  },
  setup(_, { root }) {
    const feeConfigsData = ref({ inland: [], international: [] })
    const agencyId = ref()
    const distributorsList = computed(() => store.getters['globalConfig/getDistributors'])

    async function getFeeConfigsData() {
      root.$bvModal.show('modal-api-loading')
      if (!agencyId.value) return
      const { data } = await apiAgencies.getFeeConfigs(agencyId.value)

      // Fake những source chưa có cấu hình phí xuất vé
      Object.keys(distributorsList.value).forEach(key => {
        distributorsList.value[key].forEach(sourceItem => {
          if (data.find(i => i.ticketType === key.toUpperCase() && i.airline === sourceItem)) return
          data.push({
            id: uuidv4(),
            airline: sourceItem,
            feeType: 'FLIGHT_ITINERARY',
            amount: 0,
            ticketType: key.toUpperCase(),
          })
        })
      })

      feeConfigsData.value = {
        inland: data.filter(fitem => fitem.ticketType === 'INLAND'),
        international: data.filter(fitem => fitem.ticketType === 'INTERNATIONAL'),
      }
      root.$bvModal.hide('modal-api-loading')
    }

    onMounted(() => {
      store.dispatch('globalConfig/getAvailableDistributorSource')
      agencyId.value = getUserData().employeeData?.agency?.id
      if (!agencyId.value) {
        return
      }
      getFeeConfigsData()
    })
    return {
      feeConfigsData, getFeeConfigsData, agencyId,
    }
  },
}
</script>
